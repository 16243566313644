// Details inside of template/event
import React from 'react'

import EventBadge from 'components/shared/eventBadge'
import Vibes from 'components/elements/vibes'

const Details = (props) => {
  const { eventDetails } = props
  const {
    id,
    properties,
    title
  } = eventDetails || {}

  const {
    description,
    hotspots_place,
    location,
    name,
    price,
    start_date,
    end_date,
    vibes = [],
    vibemap_images,
    url
  } = properties || {} // Safely destructure

  //console.log(`Vibes `, vibes);
  const image = vibemap_images && vibemap_images.length > 0
    ? vibemap_images[0]
    : hotspots_place?.properties?.vibemap_images
      ? hotspots_place?.properties?.vibemap_images[0]
      : null

  const placeVibes = hotspots_place?.properties?.vibes
    ? hotspots_place?.properties?.vibes
    : []

  const vibesCombined = vibes.concat(placeVibes)

  return (
    <>
      <h1>{name ? name : title}</h1>
      <section>
        <EventBadge dateTime={start_date} />
        <img
          alt={image?.alt_text}
          caption={image?.caption}
          className='eventImage'
          loading='lazy'
          src={image?.original}
          title={title}
        />
      </section>
      <Vibes vibes={vibesCombined} />
      <div className="description" style={{ whiteSpace: 'pre-wrap' }}>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div >


    </>
  )
}

export default Details
