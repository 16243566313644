// Details inside of template/event
import React, { lazy, Suspense, useRef } from 'react'

import { useIsVisible } from 'react-is-visible'
import { pageStore } from "state/store-zustand";

const Map = lazy(() => import('components/map'))
import DateToCalendar from 'components/shared/dateToCalendar'

const TimeLocation = (props) => {
  const ref = useRef();
  const mapRef = useRef()
  const isVisible = useIsVisible(ref)

  const { eventDetails } = props
  const {
    id,
    properties,
    title
  } = eventDetails || {}

  const isDesktop = pageStore((state) => state.isDesktop)
  const isTablet = pageStore((state) => state.isTablet)

  const {
    address,
    description,
    hotspots_place,
    location,
    start_date,
    end_date,
    likes,
    rating,
    price,
    vibemap_images,
    url
  } = properties || {} // Safely destructure

  const isFree = price && typeof price == "string" && price.toUpperCase() === 'FREE'

  const height = isDesktop || isTablet
    ? 300
    : 240

  const width = isDesktop || isTablet
    ? 400
    : 300


  const venue = hotspots_place?.properties?.name

  const timezone = hotspots_place?.properties?.timezone || 'America/Los_Angeles'

  const latitude = hotspots_place?.geometry?.coordinates[1]
  const longitude = hotspots_place?.geometry?.coordinates[0]

  let map = hotspots_place?.geometry
    ? <div className='map'>
      <Suspense fallback={<span>...</span>}>
        <Map
          mapRef={mapRef}
          latitude={latitude}
          longitude={longitude}
          height={height}
          width={width}
          dragPan={false}
          showFullScreen={false}
          showExplore={false}
          showPlaces={false}
          showDraggableMarker={false}
          map3d={false}
          scrollZoom={false}
          zoom={13}
        />
      </Suspense>
    </div>
    : null

  const ticketPrice = <span>{`${(isFree || price == null) ? ' ' : ' $'}${price ? price : ''}`}</span>

  const ticketButton = (
    <div className='ticket'>
      <a href={url} className='ui button fluid black large'>
        More Info
      </a>
    </div>
  )

  return (
    <div ref={ref} className={'info'}>
      {isVisible &&
        <div className="map-container">
          {likes &&
            <p>{`${likes} people like this`}</p>
          }
          <p>
            <strong className='price'>{ticketPrice}</strong>
            <span className='small'>Tickets starting price</span>
          </p>
          {start_date &&
            <DateToCalendar
              address={address}
              start_date={start_date}
              end_date={end_date}
              name={title}
              description={description}
              timezone={timezone} />
          }

          <div>
            <p>{venue}</p>
            <p>{address ? address : hotspots_place?.properties?.address}</p>
          </div>
          {map}
        </div>
      }
      {ticketButton}
      <p className='small'>By buying your ticket here, you support Vibemap, an authorized ticket re-seller. Note that the listing price is often discounted and maybe different from the final sale price.</p>
    </div>
  )
}

export default TimeLocation
