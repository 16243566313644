// Details inside of template/event
import React, { useState } from 'react'

import Chip from 'components/shared/chip/chip'

// TODO: Make shared component
const Vibes = (props) => {
  const {
    vibes = [],
    numVibesShown = 3
  } =  props

  const [vibesExpanded, setVibesExpanded] = useState(false)

  const toggleMoreVibes = function() {
    setVibesExpanded(!vibesExpanded)
  }

  const displayVibes = vibesExpanded
    ? vibes
    : vibes.slice(0, numVibesShown)

  const vibeButtons = displayVibes.map(vibe => {
    const pageLink = `/vibes/${vibe?.slug ? vibe.slug : vibe}`

    return <a href={pageLink} key={vibe}>
      <Chip key={vibe} text={vibe}/>
    </a>

  })
  return (
    <div className='vibes'>
      {vibeButtons}
      <a className='showMore' onClick={toggleMoreVibes}>
        {vibesExpanded ? 'Show less' : 'Show more vibes'}
      </a>
    </div>
  )
}

export default Vibes
